import React, { useState } from 'react'
import { set_onboarding } from '../../../redux/slices/uiSlice'
import { useDispatch } from 'react-redux'
import { error_alert, success_alert } from '../../../utils'
import { network_tl } from '../../../translations'
import { Axios } from '../../../api'
import { FormattedMessage, useIntl } from 'react-intl'
import { BiLoaderAlt } from 'react-icons/bi'

const Skip = () => {
    const intl=useIntl()
    const dispatch = useDispatch()
    const [loading,setLoading] = useState(false)


    const skipHandler= async()=>{
        if(loading){
            return
        }
        setLoading(true)

        let payload = {
            on_board:true,
        }

        try {
            const result = await Axios.put("api/business/company/admin/profile", payload, { requestId: "skip-meeting" });
            dispatch(set_onboarding(true))
            setLoading(false)
        } catch (e) {
            if (e.response) {
                error_alert(JSON.stringify(e.response.data.description))
            } else {
                error_alert(network_tl(intl))
            }
            setLoading(false)
        }
    }


    return (
        <div className=''>
            <div className='bg-lighter underline  cursor-pointer px-4 w-16 text-sm rounded-md'
            onClick={()=>skipHandler()}>
                {
                    loading?
                    <BiLoaderAlt className='text-sm text-primary animate-spin mx-auto'/>
                    :
                    <FormattedMessage id="skip" defaultMessage={"Skip"}/>
                }
            </div>
        </div>
    )
}

export default Skip