import React, { useState } from 'react'
import DateSelector from './DateSelector'
import TimeSelector from './TimeSelector'
import { useDispatch } from 'react-redux';
import { set_onboarding } from '../../../redux/slices/uiSlice';
import Skip from '../Skip';
import { FormattedMessage } from 'react-intl';

const Calendar = ({step,setStep,setSelectedOption,selectedOption}) => {
    const [selectedDate, setSelectedDate] = useState("");
    const [selectedTime, setSelectedTime] = useState("");
    const dispatch = useDispatch();
    
    return (
        <div className='overflow-hidden min-h-screen md:min-h-[calc(100vh-100px)] relative bg-white shadow-heavier md:rounded-[25px] flex flex-col'>
            <div className='flex-shrink-0 flex items-center justify-between pt-5 px-10'>
                <p className='text-primary inline-flex items-center space-x-2 text-center font-bold text-sm md:text-lg mb-2 md:mb-3 cursor-pointer' onClick={()=>setStep(step-1)} >
                    <img src="/images/icons/arrow-left-primary.svg" className='w-[15px] cursor-pointer md:w-[20px]' />
                    <span className='font-normal'><FormattedMessage id='back' defaultMessage={"Back"}/></span>
                </p>

                {/* <button onClick={() => {
                    dispatch(set_onboarding(true))
                }}>
                    <img src="/images/icons/close-opacity.svg" className='w-[20px] md:w-[40px]' />
                </button> */}
                <Skip/>
            </div>
            <div className='mb-8 md:mb-3 flex-shrink-0 px-4 md:px-10'>
               
                <div className='flex items-center justify-between pt-8'>
                    <p className='text-primary font-bold text-sm md:text-xl'>
                        <FormattedMessage id='pro_id_team' defaultMessage={"PRO-ID Team member"}/>
                    </p>
                    <img src="/images/icons/small-logo.png" className='w-[40px] md:w-[70px]' />
                </div>
            </div>

            <div className='flex-grow pb-10'>
                <p className='px-4 md:px-10 mb-5 text-center text-primary font-bold text-sm sm:text-base md:text-xl'>                    
                    <FormattedMessage id='what_day_suitable' defaultMessage={"What day works best for you?"}/>
                </p>
                <DateSelector selectedDate={selectedDate} setSelectedDate={setSelectedDate} setSelectedTime={setSelectedTime}/>
                {
                    selectedDate &&
                        <TimeSelector selectedOption={selectedOption} selectedTime={selectedTime} setSelectedTime={setSelectedTime} selectedDate={selectedDate}/>
                }
            </div>

            <div className='py-5 px-8 bg-lighter flex-shrink-0'>
                <div className='flex items-center justify-center space-x-3'>
                    <img src="/favicon.png" className='w-5 md:w-12'/>
                    <p className='font-light text-xs md:text-lg'>
                        <FormattedMessage id='powered_by' defaultMessage={"Powered by {boldName}"}
                            values={{
                                boldName:<span className='text-primary font-bold'>PRO-ID</span> 
                            }}
                        />
                        
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Calendar