import React, { Suspense } from 'react';
import { Route, Routes, Navigate } from "react-router";
import { LogoLoading } from "../../components/Loading";

const Login = React.lazy(()=>import('../../pages/AuthPages/Login'));
const ForgotPassword = React.lazy(()=>import('../../pages/AuthPages/ForgotPassword'));
const SetupProfile = React.lazy(()=>import('../../pages/AuthPages/SetupProfile'));
const SetPassword = React.lazy(()=>import('../../pages/AuthPages/SetPassword'));
const AccountTransferConsent = React.lazy(()=>import('../../pages/AuthPages/AccountTransferConsent'));


function AuthRoute() {

    return (
        <>
            <Suspense fallback={<LogoLoading />}>
                <Routes>
                    <Route path='/' element={<Login />} />
                    <Route path={"/forgot-password"} element={<ForgotPassword />} />
                    <Route path={"/setup-profile/:token"} element={<SetupProfile />} />
                    <Route path={'/set-password/:token/:otp'} element={<SetPassword />} />
                    <Route path={'/account-transfer-consent/:token'} element={<AccountTransferConsent />} />

                    <Route path='*' element={<Navigate to="/" />} />
                </Routes>
            </Suspense>
        </>
    )
}

export default AuthRoute
