import React, { useEffect, useState } from 'react'
import { BiDotsVerticalRounded, BiLoaderAlt } from 'react-icons/bi'
import { HiOutlineLightBulb } from "react-icons/hi2"
import { useDetectClickOutside } from 'react-detect-click-outside';
import { BiLogOut } from "react-icons/bi";
import { VscRequestChanges } from "react-icons/vsc";
import { IoClose } from 'react-icons/io5';
import FeatureRequest from '../../../pages/SuperAdmin/FeatureRequest';
import { BasePopupContext } from '../../BasePopup';
import { POPUP_MEDIUM } from '../../../constants';
import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { error_alert, removeToken, success_alert } from '../../../utils';
import { set_sidebar } from '../../../redux/slices/uiSlice';
import { logout } from '../../../redux/slices/userSlice';
import { useNavigate } from 'react-router-dom';
import { Axios } from '../../../api';
import { FormattedMessage, useIntl } from 'react-intl';
import { CgProfile } from "react-icons/cg";
import { network_tl } from '../../../translations';

const ProfileTab = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const intl = useIntl()

    const [showPopup, setShowPopup] = useState(false)
    const ref = useDetectClickOutside({ onTriggered: () => setShowPopup(false) });
    const {setPopup} = useContext(BasePopupContext)
    const sidebar = useSelector(state => state.ui.sidebar);
    const user = useSelector(state => state.user);
    const [loading,setLoading] = useState(false)


    const handlePopup = ()=>{
        const popup = {
          hideDefaultClose: false,
          custom_component: <FeatureRequest/>,
          visible: true,
          heading: "",
          contained: false,
          size: POPUP_MEDIUM,
          contained: true
      }
      setPopup(popup)
    }


    useEffect(()=>{
        setShowPopup(false)
    },[sidebar])

    
    const handleLogout = async () => {
        setLoading(true)
        try{
            const result = await Axios.get('api/user/logout/admin');
            removeToken();
            dispatch(set_sidebar(false))
            dispatch(logout(false))
            success_alert(intl.formatMessage({id:"user_logout", defaultMessage:"User logged out successfully!" }))
            navigate('/')
            setLoading(false)
        } catch(e){
            if(e){
                error_alert(JSON.stringify(e.response.data.description));
            } else{
                error_alert(network_tl(intl));
            }
            setLoading(false)
        }
    }

    return (
        <div className='mt-10 relative flex justify-end p-7 sidebar-profile-shadow bg-white shadow-xl'>
            <div className=' w-[95%]'>
                <div className='flex items-center justify-between'>
                    <p className='font-semibold text-base text-primary'>
                        <FormattedMessage id='whats_new' defaultMessage={"What{singleapostrophe}s New?"}
                        values={{
                            singleapostrophe:<span>'</span>
                        }}


                        />
                    </p>
                    <HiOutlineLightBulb className='text-primary' />
                </div>

                <div className='mt-5 flex items-center justify-between'>
                    <div className='space-x-3 flex items-center'>
                        <div className='h-10 w-10 rounded-full flex-shrink-0 text-white flex justify-center items-center bg-primary'>
                            {user.first_name[0]}
                        </div>
                        <div>
                            <p className='font-medium text-sm text-primary'>
                                {user.email.slice(0,10)}...
                            </p>
                            <p className='font-medium text-xs text-primary line-clamp-1'>
                                {user.first_name}
                            </p>
                        </div>

                    </div>
                    <button ref={ref}>
                        <BiDotsVerticalRounded  className='text-3xl' onClick={() => {
                            if(!loading){
                                setShowPopup(!showPopup)
                            }
                        }} />
                    </button>
                </div>
            </div>
            {
                showPopup &&
                <div>
                    <div className='absolute rounded-xl -translate-y-[90%]  right-3 bg-primary shadow-lg min-h-[170px] min-w-[200px]'>
                        <div className={`text-white flex justify-end p-2 mb-2 text-3xl cursor-pointer`}>
                            <IoClose className='text-white hover:text-lighter ' onClick={()=>{
                                if(!loading){
                                    setShowPopup(false)
                                }
                            }} />
                        </div>
                        <div className='-mt-2 pb-6'>

                            <div className={`space-x-5 px-4  cursor-pointer transition-all  group hover:bg-white  justify-start flex items-center py-4 `}
                            onClick={()=>{
                                if(!loading){
                                    handleLogout()
                                }
                            }}>
                                {
                                    loading?
                                    <BiLoaderAlt className='text-xl group-hover:text-primary text-white animate-spin'/>
                                    :
                                    <BiLogOut className='text-xl text-white group-hover:text-primary' />
                                }
                                <span className={`font-regular text-sm ${"text-white group-hover:text-primary group-hover:font-medium"}`}>
                                <FormattedMessage id='logout' defaultMessage={"Logout"}/>
                                </span>
                            </div>
                            
                            
                            <div className={`space-x-5 px-4  cursor-pointer transition-all  group hover:bg-white  justify-start flex items-center py-4 `}
                            onClick={()=>navigate('/my-profile')}>
                                <CgProfile className='text-xl text-white group-hover:text-primary' />
                                <span className={`font-regular text-sm ${" text-white group-hover:text-primary group-hover:font-medium"}`}>
                                    <FormattedMessage id='my_profile' defaultMessage={"My Profile"}/>
                                </span>
                            </div>

                            <div className={`space-x-5 px-4  cursor-pointer transition-all  group hover:bg-white  justify-start flex items-center py-4 `}
                            onClick={()=>handlePopup()}>
                                <VscRequestChanges className='text-xl text-white group-hover:text-primary' />
                                <span className={`font-regular text-sm ${" text-white group-hover:text-primary group-hover:font-medium"}`}>
                                    <FormattedMessage id='feature_request' defaultMessage={"Feature Request"}/>
                                </span>
                            </div>
                            
                        </div>
                        <div class="triangle-down absolute -bottom-3 right-3 "></div>
                    </div>
                </div>
            }
        </div>
    )
}

export default ProfileTab