import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    refresh: 0,
    data: [],
    dropdown: []
}

const uiSlice = createSlice({
    initialState,
    name: "refresh",
    reducers: {
        set_data: (state, action) => {
            state.data = action.payload
        },
        set_dropdown: (state, action) => {
            state.dropdown = action.payload
        },
        refresh_category_list: (state, action) => {
            state.refresh = state.refresh+1
        },
        reset_category_list:(state,action)=>{
            return initialState
        }
    }
})

export default uiSlice.reducer;

export const {
    refresh_category_list,
    set_data,
    set_dropdown,
    reset_category_list
} = uiSlice.actions;
