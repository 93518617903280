import React from 'react'
import Form from './Form'

const Welcome = ({setStep,selectedOption,setSelectedOption}) => {
    return (
        <div className='min-h-screen md:min-h-[calc(100vh-100px)] relative bg-white shadow-heavier md:rounded-[25px] flex flex-col md:grid md:grid-cols-2 gap-0'>
            <div className='order-2 md:order-1 bg-white relative z-[10] md:rounded-[25px] md:shadow-xl'>
                <Form setStep={setStep} selectedOption={selectedOption} setSelectedOption={setSelectedOption}/>
            </div>
            <div className='md:rounded-[25px] order-1 md:order-2 md:absolute z-[1] top-0 bottom-0 right-0 w-full md:w-[60%] bg-no-repeat bg-cover bg-right h-[200px] md:h-full' style={{
                backgroundImage: "url(/images/bgs/cards.jpg)"
            }}>

            </div>
        </div>
    )
}

export default Welcome