import React, { useEffect, useState } from 'react'
import Select from "react-select";
import { selectStylesFilter } from '../../../utils';
import { INDUSTRY_CONST } from '../../../constants';
import Skip from '../Skip';
import { FormattedMessage, useIntl } from 'react-intl';
import { network_tl } from '../../../translations';

const Form = ({ setStep,selectedOption, setSelectedOption}) => {
    const [error,setError]=useState("")
    const intl = useIntl()



    const handleLetUsknow=()=>{
        if(!(selectedOption&&selectedOption.value)){
            setError(network_tl(intl))
            return 
        }else{
            setError("")
        }
        setStep(1)
    }


    useEffect(()=>{
        if(selectedOption&&selectedOption.value){
            setError("")
        }
    },[selectedOption])

    return (
        <div className='px-4 md:px-12 py-12'>
            <div className='flex justify-end'>
                <Skip />
            </div>
            <div className='mb-12'>
                <h2 className='mb-6 font-bold text-2xl md:text-3xl text-primary'>
                    <FormattedMessage id='welcom_to_pro_id_teams' defaultMessage={"Welcome to PRO-ID Teams!"}/>
                </h2>
                <p className='text-sm md:text-base text-primary'>
                    <FormattedMessage id='please_take_a_moment' defaultMessage={"Please take a moment to help us deliver the right experience for your business"}/>
                </p>
            </div>

            <p className='mb-5 text-base md:text-lg text-primary font-bold'>
                <FormattedMessage id='what_industory' defaultMessage={"What industry is your company in?"}/>
            </p>

            <Select
                isDisabled={false}
                styles={selectStylesFilter(false)}
                isSearchable={false}
                options={INDUSTRY_CONST}
                onChange={value => setSelectedOption(value)}
                value={selectedOption}
                isMulti={false}
                isClearable={false}
            />
            {
                error&&
                <p className='text-red-500 text-xs'>{error}</p>
            }

            <button
                className='mt-5 btn btn-primary !text-base'
                onClick={()=>handleLetUsknow()}>
                <FormattedMessage id='let_us_know' defaultMessage={"Let us Know"}/>
            </button>

        </div>
    )
}

export default Form