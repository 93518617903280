import React from 'react'
import {Link} from 'react-router-dom';
import NestedLink from './NestedLink';
import { superAdminLinks } from '../../routes/SuperAdmin/superAdminLinks';
// import { businessAdminLinks } from '../../routes/BusinessAdmin/businessAdminLinks';
import { IoLogOutOutline } from "react-icons/io5";
import { useDispatch, useSelector } from 'react-redux';
import {Axios} from '../../api/index';
import { error_alert, removeToken, success_alert } from '../../utils';
import { logout } from '../../redux/slices/userSlice';
import { BUSINESS_ADMIN, SUPER_ADMIN } from '../../constants';
import { useIntl } from 'react-intl';
import ProfileTab from './ProfileTab';
import { set_sidebar } from '../../redux/slices/uiSlice';
import LinkContainer from './Link'


const   Sidebar = () => {
  const sidebar = useSelector(state => state.ui.sidebar);
  const user = useSelector(state => state.user);
  const dispatch = useDispatch()
  // const links = user.role === SUPER_ADMIN ? superAdminLinks: user.role === BUSINESS_ADMIN ? businessAdminLinks : [];
  const links = superAdminLinks
  const intl = useIntl()
  
  const handleLogout= async ()=>{
      try{
          // const result = await Axios.get("api/users/logout", {requestId: "logout"});
          removeToken();
          dispatch(logout())
          success_alert("You have been logout successfully!");
      } catch(e){
          if(e.response){
              error_alert(JSON.stringify(e.response.data.description))
          } else{
              error_alert("Network Error!")
          }
      }
  }
  

  return (
    <>
      <div className={`sidebar-shadow rounded-tr-[30px] rounded-br-[30px] z-[101] transition-transform overflow-hidden bg-white flex flex-col fixed top-0 bottom-0 w-[230px] pt-12 transform lg:translate-x-0 ${sidebar ? "translate-x-0" : "translate-x-[-100%]"}`}>
          <Link to={'/'}>
            <div className="flex-shrink-0 px-2">
                <img src="/images/icons/logo.svg" alt="" className=' cursor-pointer mb-4 w-[85%]' />
            </div>
          </Link>

        <div className="flex-grow space-y-1 overflow-y-auto">
          {
            links.map((item, index) => (
              item.links ?
                <NestedLink title={item.title} icon={item.icon} to={item.to} links={item.links} />
                :
                <LinkContainer title={item.title} icon={item.icon} to={item.to} /> 

            ))
          }
          
        </div>

        <div className="flex-shrink-0">
          <ProfileTab />
        </div>

      </div>
      {
        sidebar ?
          <div onClick={() => dispatch(set_sidebar(false))} className="bg-black bg-opacity-25 fixed top-0 left-0 right-0 bottom-0 z-[100]"></div>
          : null
      }
    </>
  )
}

export default Sidebar