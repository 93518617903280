import React from 'react'
import Form from './Form'
import Skip from '../Skip'
import { FormattedMessage } from 'react-intl'

const Help = ({ setStep, step }) => {
    return (
        <div className='overflow-hidden min-h-screen md:min-h-[calc(100vh-100px)] relative bg-white shadow-heavier md:rounded-[25px] flex flex-col md:grid grid-cols-2'>
            <div className='order-2 md:order-1 flex flex-col justify-between relative z-[10] md:rounded-[25px]  ' >
            <div className='flex justify-between mt-4 p-4'>
                <div className='text-primary  inline-flex items-center space-x-3 text-center cursor-pointer font-bold text-sm md:text-lg mb-2 md:mb-3'
                onClick={() => setStep(step - 1)}>
                    <img src="/images/icons/arrow-left-primary.svg" className='w-[15px] cursor-pointer md:w-[20px]'  />
                    <p className='text-sm font-normal'><FormattedMessage id='back' defaultMessage={"Back"}/></p>
                </div>
                <Skip />
            </div>
                
                <Form setStep={setStep} />
            </div>

            <div className='py-5 md:py-0 order-1 md:order-2 relative bg-lighter flex items-center justify-center sm:justify-end'>
                <img src="/images/bgs/help.png" className='w-[40%] md:w-[65%] 2xl:w-[75%] 3xl:w-[85%] ' />
            </div>
        </div>
    )
}

export default Help