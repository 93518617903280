import { FormattedMessage } from "react-intl";

export const BASE_URL = "https://api.pro-id.co/" //tayyab 
// export const BASE_URL = "https://pro-id-dev-api.astutesoftwares00.com/" // Development
// export const BASE_URL = "http://192.168.0.108:8000/" // Local 
// export const BASE_URL = "https://f666-103-31-104-80.ngrok-free.app/" // Ngrok 
// export const BASE_URL = "https://pro-id-stagging-api.astutesoftwares00.com/" //stagging 

export const CLIENT_BASE_URL= "https://pro-id.co" //prod
// export const CLIENT_BASE_URL= "https://proid-nextjs.netlify.app" //stagging
export const WEBSITE_SHOP_URL = `${CLIENT_BASE_URL}/shop`
export const WEBSITE_BUSINESS_PAGE_URL=`${CLIENT_BASE_URL}/pro-id-for-business`
export const PRO_ID_WEB_APP_URL = "https://my.pro-id.co/" //add to redirect on pro-id web in ownership of member consent page
// export const PRO_ID_WEB_APP_URL = "https://branding-work.netlify.app/" //add to redirect on pro-id web in ownership of member consent page
export const PRO_ID_WEB_APP_VISTOR_PROFILE_URL = `${PRO_ID_WEB_APP_URL}profile-constant`//add to redirect on pro-id web in ownership of member consent page

export const DATE_FORMAT_SERVER = "yyyy-MM-dd";
export const DATE_FORMAT_DISPLAY = "dd/MM/yyyy";

export const ACCESS_TOKEN = "pro-id-business-access";


export const LISTING_LIMIT = 10;
export const IMPORT_BY_TYPE_FILE = "file"
export const IMPORT_BY_TYPE_EMAIL = "email"


export const IMPORTED_TYPE_FILE="file"
export const IMPORTED_TYPE_GUEST="guest"
export const IMPORTED_TYPE_MEMBER="member"


export const NOTIFICATION_TYPE_PUSH="push-notification"
export const NOTIFICATION_TYPE_EMAIL = "email"

//colors
export const PRIMARY_COLOR = "#083049";
export const LIGHTER_COLOR = "#F6FBFE";
//colors

export const INDUSTRY_CONST = [
    {
        value: "accounting",
        label: <FormattedMessage id="accounting" defaultMessage={"Accounting"}/>     
    },
    {
        value: "Airlines/Aviation",
        label: <FormattedMessage id="airlines_or_aviation" defaultMessage={"Airlines/Aviation"}/> 
    },
    {
        value: "alternate-dispute",
        label: <FormattedMessage id="alternate-dispute" defaultMessage={"Alternate Dispute"}/> 
    },
    {
        value: "resolution",
        label: <FormattedMessage id="resolution" defaultMessage={"Resolution"}/>     
    },
    {
        value: "technology_and_software",
        label: <FormattedMessage id="technology_and_software" defaultMessage={"Technology and Software"}/>     
    },
    {
        value: "healthcare_and_pharmaceuticals",
        label: <FormattedMessage id="healthcare_and_pharmaceuticals" defaultMessage={"Healthcare and Pharmaceuticals"}/>     
    },
    {
        value: "finance_and_banking",
        label: <FormattedMessage id="finance_and_banking" defaultMessage={"Finance and Banking"}/>     
    },
    {
        value: "retail_and_e_commerce",
        label: <FormattedMessage id="retail_and_e_commerce" defaultMessage={"Retail and E-commerce"}/>     
    },
    {
        value: "manufacturing_and_engineering",
        label: <FormattedMessage id="manufacturing_and_engineering" defaultMessage={"Manufacturing and Engineering"}/>     
    },
    {
        value: "education_and_training",
        label: <FormattedMessage id="education_and_training" defaultMessage={"Education and Training"}/>     
    },
    {
        value: "hospitality_and_tourism",
        label: <FormattedMessage id="hospitality_and_tourism" defaultMessage={"Hospitality and Tourism"}/>     
    },
    {
        value: "marketing_and_advertising",
        label: <FormattedMessage id="marketing_and_advertising" defaultMessage={"Marketing and Advertising"}/>     
    },
    {
        value: "real_estate_and_property_management",
        label: <FormattedMessage id="real_estate_and_property_management" defaultMessage={"Real Estate and Property Management"}/>     
    },
    {
        value: "legal_services",
        label: <FormattedMessage id="legal_services" defaultMessage={"Legal Services"}/>     
    },
    {
        value: "automotive_and_transportation",
        label: <FormattedMessage id="automotive_and_transportation" defaultMessage={"Automotive and Transportation"}/>     
    },
    {
        value: "media_and_entertainment",
        label: <FormattedMessage id="media_and_entertainment" defaultMessage={"Media and Entertainment"}/>     
    },
    {
        value: "non_profit_and_social_services",
        label: <FormattedMessage id="non_profit_and_social_services" defaultMessage={"Non-Profit and Social Services"}/>     
    },
    {
        value: "government_and_public_administration",
        label: <FormattedMessage id="government_and_public_administration" defaultMessage={"Government and Public Administration"}/>     
    },
    {
        value: "consulting_and_business_services",
        label: <FormattedMessage id="consulting_and_business_services" defaultMessage={"Consulting and Business Services"}/>     
    },
    {
        value: "agriculture_and_farming",
        label: <FormattedMessage id="agriculture_and_farming" defaultMessage={"Agriculture and Farming"}/>     
    },
    {
        value: "energy_and_utilities",
        label: <FormattedMessage id="energy_and_utilities" defaultMessage={"Energy and Utilities"}/>     
    },
    {
        value: "construction_and_architecture",
        label: <FormattedMessage id="construction_and_architecture" defaultMessage={"Construction and Architecture"}/>     
    },
    {
        value: "sports_and_recreation",
        label: <FormattedMessage id="sports_and_recreation" defaultMessage={"Sports and Recreation"}/>     
    },
    {
        value: "food_and_beverage",
        label: <FormattedMessage id="food_and_beverage" defaultMessage={"Food and Beverage"}/>     
    },
    {
        value: "alternate",
        label: <FormattedMessage id="alternate" defaultMessage={"Alternate"}/>     
    },
]

//popup
export const initialPopupState = {
    component: null,
    visible: false,
    size: "small",
    heading: "",
    contained: false,
    hideDefaultClose: false,
    second_component: false
}
export const WARNING = "warning"
export const INFO = "info"
export const SUCCESS = "success"
export const ERROR = "error"
export const OPEN = "open"
export const CUSTOM="custom"

export const POPUP_SMALL = "small";
export const POPUP_MEDIUM = "medium";
export const POPUP_LARGE = "large";
//popup

export const SUPPORTED_IMAGES = ["image/png","image/jpeg","image/webp" ]

export const BOOLEAN_OPTIONS =[
    {
        label:<FormattedMessage id="yes" defaultMessage={"Yes"}/>,
        value:true
    },
    {
        label:<FormattedMessage id="no" defaultMessage={"No"}/>,
        value:false
    },
    
]
export const ACTIVE_DISABLED_OPTIONS =[
    {
        label:<FormattedMessage id="active" defaultMessage={"Active"}/>,
        value:true
    },
    {
        label:<FormattedMessage id="disabled" defaultMessage={"Disabled"}/>,
        value:false
    },
    
]

// ROLES 
export const SUPER_ADMIN = "super-admin";
export const BUSINESS_ADMIN = "company-admin";
// ROLES 


//STATUSES
export const PENDING ="pending"
export const CONFIRMED ="confirmed"
export const ACTIVE ="active"
export const BOTH="both"
export const INACTIVE ="inactive"

export const ACTIVE_STATUS = {
    value: ACTIVE,
    label: <FormattedMessage id="active" defaultMessage={"Active"}/>,
}
export const DISABLED_STATUS = {
    value: "disabled",
    label: <FormattedMessage id="disabled" defaultMessage={"Disabled"}/>,
}
export const PENDING_STATUS = {
    value: "pending",
    label: <FormattedMessage id="pending" defaultMessage={"Pending"}/>,
}
export const COMPLETED_STATUS = {
    value: "completed",
    label: <FormattedMessage id="completed" defaultMessage={"Completed"}/>,
}
export const PROCESSING_STATUS = {
    value: "processing",
    label: <FormattedMessage id="processing" defaultMessage={"Processing"}/>,
}
export const REJECTED_STATUS = {
    value: "rejected",
    label: <FormattedMessage id="rejected" defaultMessage={"Rejected"}/>,
}
//STATUSES


export const EMAIL_REGX= /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/

export const INNER_MEMBER_LINKS = [
    {
      label: <FormattedMessage id="add_by_email" defaultMessage={"Add by Email"}/>,
      value: 0,
      img: "/images/icons/envelop.svg"
    },
    {
      label: <FormattedMessage id="import_via_csv" defaultMessage={"Import via CSV"}/>,
      value: 1,
      img: "/images/icons/csv.svg"
    },
    // {
    //   label: "Add Company Profile",
    //   value: 2,
    //   img: "/images/icons/building.svg"
    // },
    {
      label: <FormattedMessage id="sync_active_directory" defaultMessage={"Sync Active Directory"}/>,
      value: 3,
    //   img: "/images/icons/active-directory.svg"
      img: "/images/icons/active-directory2.svg"
    },
    {
      label: <FormattedMessage id="google_workspace" defaultMessage={"Google Workspace"}/>,
      value: 4,
      img: "/images/icons/google-symbol.png"
    },
    // {
    //   label: "Sync Eventbrite",
    //   value: 5,
    //   img: "/images/icons/event-brite.svg"
    // },
]

export const STORE_PAGE_SIDEBAR = [
    {
      label: <FormattedMessage id="custom_accessories" defaultMessage={"Custom Accessories"}/>,
      value: 0,
      img: "/images/icons/pencil.svg"
    },
    {
      label: <FormattedMessage id="free_standard" defaultMessage={"Free Standard"}/>,
      value: 1,
      img: "/images/icons/gift.svg"
    },
    {
      label: <FormattedMessage id="orders" defaultMessage={"Orders"}/>,
      value: 2,
      img: "/images/icons/invoice.svg"
    },
]


export const NORMAL_PRODUCT="normal";
export const PHONE_CASE_CUSTOMIZE_PRODUCT ="customizable-mobile-cover";
export const CARD_CUSTOMIZE_PRODUCT="customizable-card";
export const KEYCHAIN_CUSTOMIZE_PRODUCT="customize-keychain";

export const OUR_CUSTOM_PRODUCTS = [CARD_CUSTOMIZE_PRODUCT,PHONE_CASE_CUSTOMIZE_PRODUCT,KEYCHAIN_CUSTOMIZE_PRODUCT]


export const THEME_DARK = 'dark'
export const THEME_LIGHT = 'light'
export const DARK_THEME_COLOR = "#262626"


export const ASCENDING_ORDER="asc"
export const DESCENDING_ORDER="desc"


export const HUBSPOT="hubspot"
export const SALES_FORCE="sales-force"
export const OUTLOOK="outlook"
export const GOOGLE_WORKSPACE="google-workspace"
export const ACTIVE_DIRECTORY="active-directory"
export const OCKTA_HR="ockta"

export const CRM_OPTIONS = [
    {
        title: <FormattedMessage id="hr" defaultMessage={"HR"}/>,
        options: [
            {
                title: <FormattedMessage id="active_directory" defaultMessage={"Active Directory"}/>,
                slug: "active-directory",
                field: 'is_active_directory_connected',
                slug: ACTIVE_DIRECTORY,
                custom:true,
                description: <FormattedMessage id="sync_your_team" defaultMessage={"Sync your team members from Microsoft Active Directory"}/>,
                image: "/images/icons/active-drive-blob.svg",
            },
            {
                title: <FormattedMessage id="google_workspace" defaultMessage={"Google Workspace"}/>,
                slug: GOOGLE_WORKSPACE,
                custom:true,
                field: 'is_google_workspace_connected',
                description: <FormattedMessage id="convert_pro_id_leads" defaultMessage={"Convert Pro-ID leads into {platform} contacts."}
                    values={{
                        platform:<FormattedMessage id="google_workspace" defaultMessage={"Google Workspace"}/>
                    }}
                />,
                image: "/images/icons/google-blob.svg"
            },
            {
                title: <span className="uppercase"><FormattedMessage id="ockta" defaultMessage={"okta"}/></span>,
                slug: OCKTA_HR,
                field: 'is_ockta_connected',
                description:<FormattedMessage id="convert_pro_id_leads_okta" defaultMessage={"Convert Pro-ID leads into Ockta Workspace contacts."}/>,
                image: "/images/icons/okta.svg",
                custom_class:"w-20"
            },
            
            // {
            //     title: "Eventbrite",
            //     slug:"eventbrite",
            //     connect:false,
            //     description: "Sync attendees from Eventbrite to Popl for QR code and badge management.",
            //     image: "/images/icons/eventbrite-blob.svg"
            // }
        ]
    },
    {
        title: <span className=" uppercase"><FormattedMessage id="crm" defaultMessage={"crm"}/></span>,
        options: [
            {
                title: <FormattedMessage id="hubspot" defaultMessage={"Hubspot"}/>,
                slug: HUBSPOT,
                field: 'is_hubspot_connected',
                description: <FormattedMessage id="crm_platform_with_marketing" defaultMessage={"CRM platform with marketing, sales, customer service and more"}/>,
                image: "/images/icons/hubspot-blob.svg"
            },
            {
                title: <FormattedMessage id="salesforce" defaultMessage={"Salesforce"}/>,
                slug: SALES_FORCE,
                field: 'is_sales_force_connected',
                description: <FormattedMessage id="unify_marketing_sales" defaultMessage={"Unify marketing, sales, service, commerce, and IT."}/>,
                image: "/images/icons/salesforce-blob.svg"
            },
            {
                title: <FormattedMessage id="outlook" defaultMessage={"Outlook"}/>,
                slug: OUTLOOK,
                field: 'is_outlook_connected',
                description: <FormattedMessage id="convert_pro_id_leads" defaultMessage={"Convert Pro-ID leads into {platform} contacts."}
                values={{
                    platform:<FormattedMessage id="outlook" defaultMessage={"Outlook"}/>
                }}
                />,
                image: "/images/icons/outlook-blob.svg"
            },
        ]
    },


]

export const ALPHABETS_REGEX =/^[a-z]+$/i
export const STRONG_PASSWORD_REGEX = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,}$/  //minimum 8, must 1 number, 1uppercase, 1 lowercase, 1 special character exit in password
// export const CLIENT_ID_GOOGLE_WORKSPACE=process.env.NODE_ENV === "production"? "707422340907-60gaie3upltctm5c1o35vu3qs3vlmcgq.apps.googleusercontent.com":"1044307725890-ffc6fh9ss81pjugv7spr7nh2qa0056g8.apps.googleusercontent.com"
export const CLIENT_ID_GOOGLE_WORKSPACE="707422340907-60gaie3upltctm5c1o35vu3qs3vlmcgq.apps.googleusercontent.com"
export const MY_SUBSCRIPTIN_ROUTE ="/subscription"
export const FEATURE_REQUEST_URL ="/feature-request"