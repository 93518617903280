import { createSlice } from "@reduxjs/toolkit";


const initialState =false


const licenseSlice = createSlice({
    name:"license-slice",
    initialState,
    reducers:{
        set_license_data : (state, action)=>{
            return action.payload        
        },
        set_refresh_license: (state,action)=>{
            state.refresh = !state.refresh
            return state 
        },
        reset_license_data: (state,action)=>{
            state = initialState
            return state
        }
    }
})
export default licenseSlice.reducer;

export const {
    set_license_data,
    reset_license_data,
    set_refresh_license
} = licenseSlice.actions;
