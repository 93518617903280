import { DateTime } from 'luxon';
import React, { useState } from 'react'
import { DATE_FORMAT_SERVER } from '../../../constants';
import {IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

const Form = ({selectedDate, setSelectedDate, setSelectedTime}) => {

    const [currentWeekStart, setCurrentWeekStart] = useState(DateTime.now().startOf('week'));
    
    const today = DateTime.now();


    const goToPreviousWeek = () => {
        setCurrentWeekStart(currentWeekStart.minus({ weeks: 1 }));
    };

    const goToNextWeek = () => {
        setCurrentWeekStart(currentWeekStart.plus({ weeks: 1 }));
    };

    const isCurrentWeek = () => {
        const today = DateTime.now();
        return currentWeekStart <= today && today < currentWeekStart.plus({ weeks: 1 });
    };

    const renderDaysOfWeek = () => {
        const daysOfWeek = Array.from({ length: 7 }, (_, index) =>
            currentWeekStart.plus({ days: index })
        );
        return daysOfWeek
    }


    return (
        <div className='px-3 md:px-10 xl:w-[90%] mx-auto'>

            <div className='flex items-center justify-center space-x-3'>
                {/* left */}
                <button 
                    onClick={goToPreviousWeek}
                    disabled={currentWeekStart <= today && today < currentWeekStart.plus({ weeks: 1 })}
                    className='disabled:bg-gray-300 flex-shrink-0 h-5 w-5 sm:h-8 sm:w-8 rounded-full bg-primary flex items-center justify-center'
                >
                    <IoIosArrowBack className='sm:text-xl text-white'/> 
                </button>
                {/* left */}

                {/* calendar */}
                <div className='flex-grow flex-shrink-0 border-2 border-primary/20 bg-lighter py-[10px] px-4 lg:px-[50px] rounded-[20px]'>
                    <div className='lg:w-[95%] mx-auto flex items-center'>

                        {
                            renderDaysOfWeek().map((item, index) => (
                                <div
                                    onClick={() => {
                                        if(DateTime.fromISO(item).toFormat(DATE_FORMAT_SERVER) >=  DateTime.now().toFormat(DATE_FORMAT_SERVER)){
                                            setSelectedDate(item.toFormat(DATE_FORMAT_SERVER))
                                            setSelectedTime("")
                                        }else{
                                            return 
                                        }
                                    }}
                                    key={index}
                                    className={` ${DateTime.fromISO(item).toFormat(DATE_FORMAT_SERVER) >=  DateTime.now().toFormat(DATE_FORMAT_SERVER)?"cursor-pointer":" cursor-not-allowed "}  select-none flex-grow flex-shrink-0`}
                                >
                                    <div className={`py-5 lg:py-12 rounded-xl sm:rounded-[20px]
                                    ${DateTime.fromISO(item).toFormat(DATE_FORMAT_SERVER) <  DateTime.now().toFormat(DATE_FORMAT_SERVER)&&"bg-gray-100/70 w-[95%] sm:w-[80%] mx-auto"}
                                    ${item.toFormat(DATE_FORMAT_SERVER) === selectedDate ? "w-[95%] sm:w-[80%] mx-auto bg-gradient-to-b from-secondary to-primary text-white" : "w-full"}`}>
                                        <p className={`mb-2 text-center text-[10px] sm:text-sm font-medium ${item.toFormat(DATE_FORMAT_SERVER) === selectedDate ? "text-white" : "text-primary"}`}>
                                            {
                                                item.toFormat("ccc")
                                            }
                                        </p>

                                        <div className={`h-2 border-b-2 border-dashed ${item.toFormat(DATE_FORMAT_SERVER) === selectedDate ? "border-transparent" : "border-primary/20"}`}>
                                        </div>

                                        <p className={`mt-3 text-center text-sm sm:text-xl lg:text-3xl font-medium ${item.toFormat(DATE_FORMAT_SERVER) === selectedDate ? "text-white" : "text-primary"}`}>
                                            {
                                                item.toFormat("d")
                                            }
                                        </p>
                                        <p className={`text-center text-[10px] sm:text-sm font-medium ${item.toFormat(DATE_FORMAT_SERVER) === selectedDate ? "text-white" : "text-primary"}`}>
                                            {
                                                item.toFormat("LLL")
                                            }
                                        </p>
                                    </div>

                                </div>
                            ))
                        }

                    </div>
                </div>
                {/* calendar */}

                <button 
                    onClick={goToNextWeek}
                    className='flex-shrink-0 h-5 w-5 sm:h-8 sm:w-8 rounded-full bg-primary flex items-center justify-center'
                >
                    <IoIosArrowForward className='sm:text-xl text-white'/> 
                </button>


            </div>

        </div>
    )
}

export default Form