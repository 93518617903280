import React, { useContext, useState } from 'react'
import Page from '../../../components/Page'
import CurrentPlan from './CurrentPlan'
import AddMembers from './AddMembers'
import PaymentMethod from './PaymentMethod'
import Invoices from './Invoices'
import { IoClose } from 'react-icons/io5'
import { BasePopupContext } from '../../../components/BasePopup'
import { hidePopup } from '../../../utils'

import { DATE_FORMAT_DISPLAY, POPUP_MEDIUM } from '../../../constants'
import { motion } from 'framer-motion'
import { useSelector } from 'react-redux'
import { DateTime } from 'luxon'
import LicenseExpired from '../../../components/LicenseExpired'
import { FormattedMessage } from 'react-intl'

const Subscription = () => {
    const { setPopup } = useContext(BasePopupContext)
    const license = useSelector((state) => state.license)
    const user = useSelector((state) => state.user)
    return (
        // <Page title="My Subscription">

        //     <div className='py-12 px-12 min-h-screen md:min-h-[calc(100vh-100px)] relative bg-white shadow-heavier rounded-[8px] md:rounded-[25px]'>

        //         <CurrentPlan />
        //         <AddMembers />
        //         <PaymentMethod/>
        //         <Invoices/>



        //     </div>

        // </Page>
        <>
            {/* header */}
            <div className="rounded-lg border-b-gray-100 flex items-center justify-between">
                <div>
                    <h1 className='text-xl sm:text-3xl text-primary font-semibold'>My Subscription</h1>
                </div>
                <div className={`text-white text-3xl cursor-pointer`}>
                    <IoClose className='text-gray-600' onClick={() => hidePopup(setPopup)} />
                </div>
            </div>
            {/* header */}

            <div className='w-full py-12 space-y-4'>
                {
                    !license.max_users ?
                        <div className='text-justify'>
                            <FormattedMessage id='your_license_has_not_activated' defaultMessage={"Your license has not been activated yet. Please reach out to the administrator at {email} for assistance in activating your license"}
                            values={{
                                email: <a href="mailto:support@pro-id.com" className='text-primary underline font-medium'>support@pro-id.com</a> 
                            }}/>.
                        </div>
                        :
                        <>
                            <div className='flex justify-end text-primary'>
                                <span>
                                    
                                {license.expired?<FormattedMessage id='expired_on' defaultMessage={"Expired"}/>:<FormattedMessage id='expire_on' defaultMessage={"Expire"}/>}: {DateTime.fromISO(license.expiration_date).toFormat(DATE_FORMAT_DISPLAY)}
                                </span>

                            </div>
                            {
                                license.expired?
                                <LicenseExpired/>
                                :
                                <>
                                <div className='flex justify-center'>
                                    <div className='grid grid-cols-3 gap-5'>
                                        <div className='flex flex-col items-center justify-center rounded-md bg-emerald-100 text-emerald-500 h-[90px] w-[200px]'>
                                            <div className='text-xs uppercase'>
                                                <FormattedMessage id='active' defaultMessage={"active"}/>
                                            </div>
                                            <div className='text-3xl'>
                                                {license.active_members}
                                            </div>
                                        </div>
                                        <div className='flex flex-col items-center justify-center rounded-md bg-pink-100 text-pink-500 h-[90px] w-[200px]'>
                                            <div className='text-xs  uppercase'>
                                                <FormattedMessage id='available' defaultMessage={"Available"}/>
                                            </div>
                                            <div className='text-3xl'>
                                                {license.max_users - license.active_members}
                                            </div>
                                        </div>
                                        <div className='flex flex-col items-center justify-center rounded-md bg-amber-100 text-amber-500 h-[90px] w-[200px]'>
                                            <div className='text-xs  uppercase'>
                                                <FormattedMessage id='maximum' defaultMessage={"Maximum"}/>
                                            </div>
                                            <div className='text-3xl'>
                                                {license.max_users}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='text-sm text-center pt-4 text-primary'>
                                    In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.
                                </div>
                                </>
                            }
                        </>
                }

            </div>
        </>
    )
}

export default Subscription

