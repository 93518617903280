import { createSlice } from "@reduxjs/toolkit";


const initialState ={
    all_members:[],
    active_members:[],
    inactive_members:[],
    refresh:false
}

const MembersSlice = createSlice({
    name:"members-slice",
    initialState,
    reducers:{
        set_all_members : (state, action)=>{
            state.all_members= action.payload
            return state  
        },
        set_active_member : (state, action)=>{
            state.active_members= action.payload
            return state      
        },
        set_inactive_member : (state, action)=>{
            state.inactive_members= action.payload
            return state      
        },
        set_refresh_members: (state,action)=>{
            state.refresh = !state.refresh
            return state 
        },
        reset_members_data: (state,action)=>{
            state = initialState
            return state
        }
    }
})
export default MembersSlice.reducer;

export const {
    set_active_member,
    set_refresh_members,
    reset_members_data,
    set_inactive_member,
    set_all_members
} = MembersSlice.actions;
