import { store } from "react-notifications-component";
import * as yup from "yup";
import { FormattedMessage } from "react-intl";
import { ACCESS_TOKEN, BASE_URL, DATE_FORMAT_SERVER, LIGHTER_COLOR, PRIMARY_COLOR, initialPopupState } from "./constants";
import Swal from "sweetalert2";
import { isArray } from "lodash";
import { DateTime } from "luxon";

export const getAuthUser = () => {
    let token = localStorage.getItem(ACCESS_TOKEN);
    if (token) {
        return JSON.parse(token).user;
    } else {
        return false;
    }
}

export const getToken = () => {
    let token = localStorage.getItem(ACCESS_TOKEN);
    if (token) {
        return JSON.parse(token).access_token;
    } else {
        return false;
    }
}

export const setToken = (access_token) => {
    localStorage.setItem(ACCESS_TOKEN, JSON.stringify(access_token));
}

export const removeToken = () => {
    localStorage.removeItem(ACCESS_TOKEN);
}

export const success_alert = (message, title) => {
    let new_msg = "";
    if ((message[0] === '"' || message[0] === "'") && (message[message.length - 1] === '"' || message[message.length - 1] === "'")) {
        new_msg = message.substr(1, message.length - 2);
    } else {
        new_msg = message
    }
    store.addNotification({
        title: title ? title : "",
        message: new_msg,
        className: "noti",
        type: "success",
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeInUp"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
            duration: 7000,
            onScreen: true,
            showIcon: true
        }
    })
}

export const error_alert = (message, title) => {
    let new_msg = "";
    if ((message[0] === '"' || message[0] === "'") && (message[message.length - 1] === '"' || message[message.length - 1] === "'")) {
        new_msg = message.substr(1, message.length - 2);
    } else {
        new_msg = message
    }
    store.addNotification({
        title: title ? title : "",
        message: new_msg,
        className: "noti",
        type: "danger",
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__slideInRight"],
        animationOut: ["animate__animated", "animate__slideOutBottom"],
        dismiss: {
            duration: 7000,
            onScreen: true,
            showIcon: true
        }
    })
}

export const warning_alert = (message, title) => {
    store.addNotification({
        title: title ? title : "",
        message: message,
        type: "warning",
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
            duration: 7000,
            onScreen: true,
            showIcon: true
        }
    })
}


export const success_popup_alert =(title,message,btnText)=>{
    Swal.fire({
        title: title,
        text: message,
        icon: 'success',
        animation:true,
        confirmButtonColor:'#083049',
        confirmButtonText: btnText,
        })
}
export const error_popup_alert =(title,message,btnText)=>{
    Swal.fire({
        title: title,
        text: message,
        icon: 'error',
        animation:true,
        confirmButtonColor:'#991b1b',
        confirmButtonText: btnText?btnText:'Close',
        })
}
export const warning_popup_alert =(title,message,btnText)=>{
    Swal.fire({
        title: title,
        text: message,
        icon: 'warning',
        animation:true,
        confirmButtonColor:'#083049',
        confirmButtonText: btnText?btnText:'Close',
        })
}


export function stringNotEmpty() {
    return yup.mixed().test({
        name: 'stringNotEmpty',
        exclusive: false,
        message: <FormattedMessage id="required" defaultMessage="Required" />,
        test: function (value) {
            if (value !== undefined && value !== false) {
                return value.trim() !== "";
            } else if (value === undefined) {
                return false
            }
        },
    });
}


export const stringMaxLength = (length) => {
    return {
        name: 'lessthan100',
        exclusive: false,
        message: <FormattedMessage id="max.length" defaultMessage="Only {length} Characters are allowed" values={{
            length:length
        }} />,
        test: function (value) {
            if (value) {
                let trimedVal = value ? value.trim() : null
                if (trimedVal && trimedVal.length <= length) {
                    return true
                } else {
                    return false
                }
            } else {
                return false
            }
        },
    }
}
// export const selectStyles = error => (
//     {
//         control: (provided, state) => ({
//             ...provided,
//             backgroundColor: state.isDisabled ? "#fff" : "#fff",
//             borderRadius: "0.375rem",
//             height:"45px",
//             border: state.isDisabled ? "2px solid #e4e4e4" : state.isFocused ? "2px solid #083049" :  state.isSelected ? "2px solid #083049" : state.hasValue ? "2px solid #10b981": error ? "2px solid #ef4444": "2px solid #cbd5e1",
//             // boxShadow: state.isFocused ? "0px 0px 6px #4c0788" : "none",
//             "&:hover": {
//                 border: "2px solid #083049",
//                 // boxShadow: "0px 0px 4px #083049"
//             },
//             fontSize: "0.875rem",
//             "@media (max-width:1024px)": {
//                 fontSize: "0.875rem"
//             }
            
//         }),
//         option: (provided, state) => {
//             return ({
//                 ...provided,
//                 background: state.isSelected ? "#e8e8e8" : "white",
//                 color: state.isSelected ? "#000000" : "#000000",
//                 "&:hover": {
//                     "background": "#e8e8e8",
//                     color: "#000000"
//                 },
//                 fontSize: "0.875rem",
//                 "@media (max-width:1024px)": {
//                     fontSize: "0.875rem"
//                 }
//             })
//         },
        
//         singleValue: (provided, state) => {
//             const opacity = state.isDisabled ? 0.5 : 1;
//             const transition = 'opacity 300ms';
    
//             return { ...provided, opacity, transition };
//         }
//     }
// )
export const selectStyles = (error, hasValue) => ({
    control: (provided, state) => ({
      ...provided,
      backgroundColor: state.isDisabled ? "#fafafa" : "#f6fbff",
      borderRadius: "12px",
      minHeight:"60px",
      paddingTop:"10px",
      paddingBottom:"10px",

      border: state.isDisabled
        ? "1px solid #d1d5db"
        : state.isFocused
        ? "1px solid #E5E7EB"
        : state.isSelected
        ? "1px solid #ef4444"
        : state.hasValue || hasValue
        ? "1px solid #10B981"
        : error
        ? "1px solid #ef4444"
        : "1px solid #E5E7EB",
      boxShadow: state.isFocused ? "0px 0px 0px #000000" : "none",
      "&:hover": {
        border: "1px solid #E5E7EB",
        boxShadow: "0px 0px 0px #000000",
      },
      fontSize: "1rem",
      "@media (max-width:1024px)": {
        fontSize: "0.875rem",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      background: state.isSelected ? "#083049" : "white",
      color: state.isSelected ? "#fff" : "#500",
      "&:hover": {
        background: "#083049",
        color: "#fff",
      },
      fontSize: "1rem",
      "@media (max-width:1024px)": {
        fontSize: "0.875rem",
      },
    }),
});

export const selectStylesSmaller = (error, hasValue) => ({
    control: (provided, state) => ({
      ...provided,
      backgroundColor: state.isDisabled ? "#fafafa" : "#f6fbff",
      borderRadius: "14px",
      fontSize: "0.875rem",
    //   height: '48px',
    //   minHeight: '48px',
      borderRadius: "0.75rem",
      border: state.isDisabled
        ? "2px solid #d1d5db"
        : state.isFocused
        ? "2px solid #000000"
        : state.isSelected
        ? "2px solid #ef4444"
        : state.hasValue || hasValue
        ? "2px solid #10B981"
        : error
        ? "2px solid #ef4444"
        : "2px solid #cbd5e1",
      boxShadow: state.isFocused ? "0px 0px 0px #000000" : "none",
      "&:hover": {
        border: "2px solid #000000",
        boxShadow: "0px 0px 0px #000000",
      },
      fontSize: "1rem",
      position: "relative", // Add this line
      "@media (max-width:1024px)": {
        fontSize: "0.875rem",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      background: state.isSelected ? "#083049" : "white",
      color: state.isSelected ? "#fff" : "#083049",
      "&:hover": {
        background: "#083049",
        color: "#fff",
      },
      fontSize: "0.875rem",
      "@media (max-width:1024px)": {
        fontSize: "0.875rem",
      },
    }),
    placeholder: (provided, state) => ({
      ...provided,
      fontSize: "0.875rem"
    }),
    singleValue: (provided, state) => ({
      ...provided,
      position: "absolute", // Add this line
      top: "50%", // Add this line
      transform: "translateY(-50%)", // Add this line
      opacity: state.isDisabled ? 0.5 : 1,
      transition: "opacity 300ms",
      fontSize: "0.875rem"
    }),
});


export const selectStylesFilter = (error, hasValue) => ({
        
    control: (provided, state) => ({
        ...provided,
        // backgroundColor: state.isDisabled ? "#fafafa" : "#f6fbff",
        borderRadius: "10px",
        minHeight:"20px",
        paddingTop:"2px",
        paddingBottom:"2px",
  
        border: state.isDisabled
          ? "1px solid #d1d5db"
          : state.isFocused
          ? "1px solid #E5E7EB"
          : state.isSelected
          ? "1px solid #ef4444"
          : state.hasValue || hasValue
          ? "1px solid #10B981"
          : error
          ? "1px solid #ef4444"
          : "1px solid #E5E7EB",
        boxShadow: state.isFocused ? "0px 0px 0px #000000" : "none",
        "&:hover": {
          border: "1px solid #E5E7EB",
          boxShadow: "0px 0px 0px #000000",
        },
        fontSize: "1rem",
        "@media (max-width:1024px)": {
          fontSize: "0.875rem",
        },
      }),
        
        option: (provided, state) => ({
            ...provided,
            background: state.isSelected ? "#083049 !important" : "#FFFFFF",
            color: state.isSelected ? "#FFFFFF" :"#083049 !important",
            "&:hover": {
              background: "#083049",
              color: "#ffffff !important" ,
            },
            fontSize: "1rem",
            "@media (max-width:1024px)": {
              fontSize: "0.875rem",
            },
        }),
    }
)


export const hidePopup = (setState) => {
    setState(initialPopupState)
}

export const phoneRegExp = /^((\\+[0-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const isUrl = (string) => {
    const urlPattern = /^(?:(?:https?|ftp):\/\/)?(?:www\.)?(?:[\w-]+\.)+[a-z]{2,}(?::\d+)?(?:\/[\w#!:.?+=&%@!-]*)?$/i;
    return urlPattern.test(string);
}

export const getImage = (value) => {
    return BASE_URL+value.substr(1)
}

export const canvasToBlob=(canvas, callback) =>{
    canvas.toBlob(function(blob) {
      callback(blob);
    }, 'image/png');
}

export const customStyles = {
    // pagination: {
    //     style: {
    //         fontSize: '12px',
    //         color: "#94a3b8",
    //         fontWeight:"600",
    //         minHeight: '0',
    //         // borderRadius: "10px",
    //         // marginTop: "10px",
    //         border: "none",
    //         background: "none",
    //     },
    //     // pageButtonsStyle: {
    //     //     height: "auto",
    //     //     width: "auto",
    //     //     // padding: "10px 20px",
    //     //     background: "white",
    //     //     // borderRadius: "10px",
    //     //     boxShadow: "0 0 3px 0 rgba(0,0,0,0.2)",
    //     //     "&:disabled": {
    //     //         boxShadow: "0 0 3px 0 rgba(0,0,0,0.4)",
    //     //         "&:hover": {
    //     //             boxShadow: "none",
    //     //         }
    //     //     },
    //     //     "&:hover": {
    //     //         boxShadow: "0 0 3px 0 rgba(0,0,0,0.4)",
    //     //     }
    //     // }
    // },
    table: {
        style: {
            // border: "2px solid #e2e8f0",
            // borderRadius: "10px",
        }
    },
    headRow: {
        style: {
            backgroundColor: "#ffffff",
            paddingLeft: "10px",
            paddingRight: "10px",
        }
    },
    
    rows: {
        style: {
            backgroundColor: "white",
            minHeight: '80px', // override the row height
            borderBottom: "0px solid #ccc !important",
            paddingTop:'20px',
            paddingBottom:'20px',
            paddingLeft: "10px",
            paddingRight: "10px",
            
        },
        highlightOnHoverStyle: {
            transitionDuration: '0.15s',
            backgroundColor: "#fff",
        },
    },
    headCells:{
        style:{
            color:"#94a3b8",
            textTransform: "capitalize",
            fontSize:"13px",
            fontWeight: "600",
        }
    },
    cells: {
        style: {
            color: "#000",
            fontWeight: "400",
            fontSize: "0.8rem",
            lineHeight: "1.5"
        },
    },
};

export const calculateDiscountPrice=(discountPercentage,totalPrice,discount_amount)=>{
    if(discountPercentage*totalPrice){
        const discountAmount = (totalPrice * discountPercentage) / 100;
        const discountedPrice = totalPrice - discountAmount;

        if(discount_amount){
            return discountAmount<0? 0: discountAmount
        }else{
            return discountedPrice<0 ? 0: discountedPrice
        }

    }else{
        return 0
    }
}

export const isInt=(n)=>{
    return Number(n) === n && n % 1 === 0;
}


// export const howMuchIsOff=(totalPrice,discountPrice,sendWithoutFloating)=>{
export const howMuchIsOff=(totalPrice,discountPrice)=>{
    // if(totalPrice&&discountPrice){
        discountPrice=totalPrice-discountPrice
        let off_value=((totalPrice - discountPrice) / totalPrice) * 100
        
        // return Number.isInteger(off_value)? off_value:parseInt(off_value)
        return off_value
    
    
}

export const formatMoney = (amount) => {
    if (amount >= 1000000) {
      return (amount / 1000000).toFixed(0) + "M";
    } else if (amount >= 1000) {
      return (amount / 1000).toFixed(0) + "k";
    } else {
      return amount ? amount.toString():0;
    }
  };


export const CopiedToClipBoard=(text)=>{
        navigator.clipboard.writeText(text)
}

export const downloadFileWithStreamingResponse=(responseText,fileName)=> {
      // Split the response text into lines
  const lines = responseText.split('\n');

  // Create a header row and CSV content
  const headers = lines[0].split(',');
  console.log("headers",headers)
  
  const csvContent = lines.slice(1) // Remove the header line
    .map(line => line.split(',').map(cell => cell.trim()).join(','))
    .join('\n');

  // Combine headers and content to form the complete CSV data
  const csvData = `${headers.join(',')}\n${csvContent}`;

  // Create a Blob object with the CSV data
  const blob = new Blob([csvData], { type: 'text/csv' });

  // Create a temporary URL for the Blob
  const csvURL = URL.createObjectURL(blob);

  // Create a link element
  const downloadLink = document.createElement('a');
  downloadLink.href = csvURL;
  downloadLink.download = fileName?fileName:'streaming_data.csv'; // Set the file name

  // Append the link to the body and trigger the download
  document.body.appendChild(downloadLink);
  downloadLink.click();

  // Clean up by revoking the object URL
  URL.revokeObjectURL(csvURL);
}

export const dataURLtoFile=(dataurl, filename)=> {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[arr.length - 1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    const randomName= Math.floor(1000 + Math.random() * 90)

    return new File([u8arr], `${randomName}.png`, {type:mime});
}

export const getTimeInMinutes = (time) => {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
};


export const disconnectCRM = (field,user)=>{
    let tokenObj = localStorage.getItem(ACCESS_TOKEN);
    let localStrgObj=JSON.parse(tokenObj)
    localStrgObj.user[field] = false 
    let reduxUser ={
        ...user
    }
    reduxUser[field] = false
    setToken(localStrgObj)
    return reduxUser
}


export const getCurrentWeekDate=()=>{
    const currentDate = DateTime.local();
    // Get the start of the week (Monday) for the current date
    const startOfWeek = currentDate.startOf('week');
    
    // Output the result
    console.log('Start of the current week:', startOfWeek.toISODate());
  
    return startOfWeek
}

export const getCurrentWeekArray = () => {
    const startOfWeek = getCurrentWeekDate()
  
    const weekData = [];
  
    for (let i = 0; i < 7; i++) {
      const currentDate = startOfWeek.plus({ days: i });
      const dayName = currentDate.toFormat('cccc'); // Full day name
      const dayCount = i + 1; // Day count, starting from 1
  
      weekData.push({
        name: dayName,
        count: 0,
        date: currentDate.toFormat(DATE_FORMAT_SERVER), // ISO date format
      });
    }
  
    return weekData;
};

export const  isFractional= (number)=> {
    return number % 1 !== 0;
}

export const calculatePercentageChange = (previousTotal, currentTotal) => {
    if (previousTotal === 0) {
      return currentTotal === 0 ? 0 : 100; // Handle the case when both are zero or previous is zero
    }
  
    let percentageChange = (((currentTotal - previousTotal) / Math.abs(previousTotal)) * 100)
    if(isFractional(percentageChange)){
        return percentageChange.toFixed(2)
    }else{
        return percentageChange;
    }

};
