import React from 'react'
import { useLocation, Link } from 'react-router-dom';

const LinkContainer = ({icon, title, to}) => {
  const { pathname } = useLocation();

  return (
    <>
        <Link to={to} className={`w-[90%] mx-auto group rounded-lg justify-between flex items-center py-3 px-3 hover:bg-lighter ${pathname === to ? "bg-lighter" : ""}`}>
          <div className='flex items-center'>
            <img src={icon} className={`flex-shrink-0 mr-3 w-[20px] ${pathname === to ? "" : "opacity-[0.65]"}`} />
            <span className={`transition-all  font-regular ${pathname === to ? "font-medium text-primary" : "group-hover:font-medium text-primary/60 group-hover:text-primary"}`}>
              {
                title
              }
            </span>
          </div>
      </Link>
    </>
  )
}

export default LinkContainer