import React, { Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router';

import { LogoLoading } from "../../components/Loading";
import Sidebar from '../../components/Sidebar';
import { BUSINESS_REQUESTS_ROUTE, ADMIN_ROUTE, MY_SUBSCRIPTIN_ROUTE, FEATURE_REQUEST_URL } from '../../constants';
import { useSelector } from 'react-redux';
import OnboardingFlow from '../../components/OnboardingFlow';
import Page from '../../components/Page';

// page imports
const Members = React.lazy(() => import('../../pages/SuperAdmin/Members'));
const MemberDetails = React.lazy(() => import('../../pages/SuperAdmin/Members/MemberDetails'));
const Subteams = React.lazy(() => import("../../pages/SuperAdmin/Subteams"))
const Leads = React.lazy(() => import("../../pages/SuperAdmin/Leads"))
const LeadsDetails = React.lazy(() => import("../../pages/SuperAdmin/Leads/LeadsDetails"))
const AutoTagging = React.lazy(() => import("../../pages/SuperAdmin/AutoTagging"))
const Accessories = React.lazy(() => import("../../pages/SuperAdmin/Accessories"))
const Integrations = React.lazy(() => import("../../pages/SuperAdmin/Integrations"))
const Store = React.lazy(() => import("../../pages/SuperAdmin/Store"))
const Insights = React.lazy(() => import("../../pages/SuperAdmin/Insights"))
const Notifications = React.lazy(() => import("../../pages/SuperAdmin/Notifications"))
const PasswordReset = React.lazy(() => import("../../pages/SuperAdmin/PasswordReset"))
const TutorialVideo = React.lazy(() => import("../../pages/SuperAdmin/TutorialVideo"))
const TeamSettings = React.lazy(() => import("../../pages/SuperAdmin/TeamSettings"))
const Requests = React.lazy(() => import("../../pages/SuperAdmin/Requests"))
const Support = React.lazy(() => import("../../pages/SuperAdmin/Support"))
const Subscription = React.lazy(() => import("../../pages/SuperAdmin/Subscription"))
const FeatureRequest = React.lazy(() => import("../../pages/SuperAdmin/FeatureRequest"))
const MyProfile = React.lazy(() => import("../../pages/SuperAdmin/MyProfile"))


const Teams = React.lazy(() => import("../../pages/SuperAdmin/Teams"))
const TeamDetails = React.lazy(() => import("../../pages/SuperAdmin/Teams/TeamDetails"))
const AddTeam = React.lazy(() => import("../../pages/SuperAdmin/Teams/InnerScreen"))

const Events = React.lazy(() => import("../../pages/SuperAdmin/Events"))
const AddEvents = React.lazy(() => import("../../pages/SuperAdmin/Events/InnerScreen"))
const EventDetails = React.lazy(() => import("../../pages/SuperAdmin/Events/EventDetails"))


const Templates = React.lazy(() => import("../../pages/SuperAdmin/Templates"))
const TemplateAdd = React.lazy(() => import("../../pages/SuperAdmin/Templates/InnerScreen"))
const TemplateDetails = React.lazy(() => import("../../pages/SuperAdmin/Templates/TemplateDetails"))

const VerifyHubspot = React.lazy(()=>import('../../pages/SuperAdmin/Integrations/VerifyHubspot'));
const VerifySalesForce = React.lazy(()=>import('../../pages/SuperAdmin/Integrations/VerifySalesForce'));
const VerifyOutlook = React.lazy(()=>import('../../pages/SuperAdmin/Integrations/VerifyOutlook'));
const VerifyGoogle = React.lazy(()=>import('../../pages/SuperAdmin/Integrations/VerifyGoogle'));
const VerifyActiveDirectory = React.lazy(()=>import('../../pages/SuperAdmin/Integrations/VerifyActiveDirectory'));

// page imports


function AdminRoutes(props) {
  const onboarding = useSelector(state => state.ui.onboarding);

  return (
    <>
      {
        onboarding!=null && !onboarding ?
        <>
        <OnboardingFlow/>
        </>
        :
        <>
          <Sidebar />
          <Suspense fallback={<LogoLoading />}>
            <Routes>
              <Route path={"/members"} element={<Members />} />
              <Route path={"/member/:id"} element={<MemberDetails />} />

              
              
              <Route path={"/subteams"} element={<Subteams />} />
              <Route path={"/auto-tagging"} element={<AutoTagging />} />
              <Route path={"/accessories"} element={<Accessories />} />
              <Route path={"/integrations"} element={<Integrations />} />
              <Route path={"/store"} element={<Store />} />
              <Route path={"/insights"} element={<Insights />} />
              <Route path={"/notfications"} element={<Notifications />} />
              <Route path={"/password-reset"} element={<PasswordReset />} />
              <Route path={"/tutorial-video"} element={<TutorialVideo />} />
              <Route path={"/team-setting"} element={<TeamSettings />} />
              <Route path={"/requests"} element={<Requests />} />
              <Route path={"/support"} element={<Support />} />
              {/* <Route path={MY_SUBSCRIPTIN_ROUTE} element={<Subscription />} /> */}
              {/* <Route path={FEATURE_REQUEST_URL} element={<FeatureRequest />} /> */}

              <Route path='/my-profile' element={<MyProfile/>}/>
              <Route path={"/leads"} element={<Leads />} />
              <Route path={"/lead/:id"} element={<LeadsDetails />} />
              
              <Route path={"/teams"} element={<Teams />} />
              <Route path={"/team/:id"} element={<TeamDetails />} />
              <Route path={"/team/add"} element={<AddTeam/>} />
              <Route path={"/team/update/:id"} element={<AddTeam isUpdate={true} />} />

              <Route path={"/events"} element={<Events />} />
              <Route path={"/event/:id"} element={<EventDetails />} />
              <Route path={"/event/add"} element={<AddEvents />} />
              {/* <Route path={"/event/edit/:id"} element={<AddEvents isUpdate={true} />} /> */}

              <Route path={"/templates"} element={<Templates />} />
              <Route path={"/template/:id"} element={<TemplateDetails />} />
              <Route path={"/template/add"} element={<TemplateAdd />} />
              <Route path={"/template/edit/:id"} element={<TemplateAdd isUpdate={true} />} />


              <Route path={"/verify-hubspot"} element={<VerifyHubspot />} />
              <Route path={"/verify-salesforce"} element={<VerifySalesForce />} />
              <Route path={"/verify-outlook"} element={<VerifyOutlook />} />            
              <Route path={"/verify-google"} element={<VerifyGoogle />} />            
              <Route path={"/verify-active-directory"} element={<VerifyActiveDirectory/>} />            
              
              <Route path='*' element={<Navigate to={"/members"} />} />
            </Routes>
          </Suspense>
        </>
      }
    </>
  )
}

export default AdminRoutes
