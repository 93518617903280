import {AiOutlineHome} from "react-icons/ai";
import { ADMIN_ROUTE, BUSINESS_REQUESTS_ROUTE, FEATURE_REQUEST_URL, MY_SUBSCRIPTIN_ROUTE } from "../../constants";
import { FormattedMessage } from "react-intl";

export const superAdminLinks = [
    {
        title: <FormattedMessage id="teams" defaultMessage={"Teams"}/>,
        icon: "/images/icons/teams.svg",
        to: "/",
        links: [
            {
                title: <FormattedMessage id="members" defaultMessage={"Members"}/>,
                icon: "/images/icons/people.png",
                to: "/members",
            },
            {
                title: <FormattedMessage id="teams" defaultMessage={"Teams"}/>,
                icon: "/images/icons/people.png",
                to: "/teams",
            },
            {
                title: <FormattedMessage id="events" defaultMessage={"Events"}/>,
                icon: "/images/icons/people.png",
                to: "/events",
            },
            // {
            //     title: "Subteams",
            //     icon: "/images/icons/people.png",
            //     to: "/subteams",
            // },
        ]

    },
    {
        title: <FormattedMessage id="leads" defaultMessage={"Leads"}/>,
        icon: "/images/icons/leads.svg",
        to: "/leads",
    },
    {
        title: <FormattedMessage id="templates" defaultMessage={"Templates"}/>,
        icon: "/images/icons/people.png",
        to: "/templates",
    },
    {
        title: <FormattedMessage id="accessories" defaultMessage={"Accessories"}/>,
        icon: "/images/icons/accessories.svg",
        to: "/",
        links: [
            {
                title: <FormattedMessage id="accessories" defaultMessage={"Accessories"}/>,
                icon: "/images/icons/accessories.svg",
                to: "/accessories",
            },
            {
                title: <FormattedMessage id="store" defaultMessage={"Store"}/>,
                icon: "/images/icons/accessories.svg",
                to: "/store",
            },
        ]

    },
    {
        title: <FormattedMessage id="admin" defaultMessage={"Admin"}/> ,
        icon: "/images/icons/user-setting.png",
        to: "/",
        links: [
            {
                title: <FormattedMessage id="request" defaultMessage={"Requests"}/>,
                icon: "/images/icons/accessories.svg",
                to: "/requests",
            },
            {
                title: <FormattedMessage id="notification" defaultMessage={"Notifications"}/> ,
                icon: "/images/icons/accessories.svg",
                to: "/notfications",
            },
            {
                title: <FormattedMessage id="password_reset" defaultMessage={"Password Reset"}/>,
                icon: "/images/icons/accessories.svg",
                to: "/password-reset",
            },
        ]

    },
    
    {
        title: <FormattedMessage id="insights" defaultMessage={"Insights"}/>,
        icon: "/images/icons/insights.svg",
        to: "/insights",
    },
    {
        title: <FormattedMessage id="integration" defaultMessage={"Integrations"}/>,
        icon: "/images/icons/integrations.svg",
        to: "/integrations",
    },
    {
        title: <FormattedMessage id="support" defaultMessage={"Support"}/>,
        icon: "/images/icons/support.svg",
        to: "/",
        links: [
            {
                title: <FormattedMessage id="support" defaultMessage={"Support"}/>,
                icon: "/images/icons/people.png",
                to: "/support",
            },
            {
                title: <FormattedMessage id="tutorial_videos" defaultMessage={"Tutorial Videos"}/>,
                icon: "/images/icons/people.png",
                to: "/tutorial-video",
            },
        ]

    },
    {
        title: <FormattedMessage id="setting" defaultMessage={"Setting"}/>,
        icon: "/images/icons/setting.svg",
        to: "/",
        links: [
            // {
            //     title: "Team Setting",
            //     icon: "/images/icons/people.png",
            //     to: "/team-setting",
            // },
            {
                title: <FormattedMessage id="my_subscription" defaultMessage={"My Subscription"}/>,
                icon: "/images/icons/people.png",
                to: MY_SUBSCRIPTIN_ROUTE,
                popup:true
            },
            {
                title: <FormattedMessage id="feature_request" defaultMessage={"Feature Request"}/>,
                icon: "/images/icons/people.png",
                to: FEATURE_REQUEST_URL,
                popup:true
            },
        ]

    },
]