import React, { useEffect, useState } from 'react'
import { POPUP_MEDIUM } from '../../../../constants'
import { useContext } from 'react';
import { IoClose } from 'react-icons/io5';
import { motion } from 'framer-motion'
import Container from './Container'
import { hidePopup } from '../../../../utils';
import { BasePopupContext } from '../../../../components/BasePopup';
import { FormattedMessage } from 'react-intl';

const FormConatiner = () => {
  const { setPopup } = useContext(BasePopupContext)

  const variants = {
    initial: {
      opacity: 0,
      top: "-100%",
    },
    enter: {
      opacity: 1,
      top: "0"
    },
    exit: {
      opacity: 1,
      top: "-100%",
    }
  }

  return (
    <>

      {/* header */}
      <div className="rounded-lg border-b-gray-100 flex items-center justify-between">
        <div>
          <h1 className='text-xl sm:text-3xl text-primary font-semibold'><FormattedMessage id='request_a_feature' defaultMessage={"Request a Feature"}/></h1>
        </div>
        <div className={`text-white text-3xl cursor-pointer`}>
          <IoClose className='text-gray-600' onClick={() => hidePopup(setPopup)} />
        </div>
      </div>
      {/* header */}

      <div className={"text-sm pt-3"}>
        <FormattedMessage id='do_you_have_any_idea' defaultMessage={"Do you have an idea for a feature that would make {pro_id} even better for you? Let us Know!"}
        values={{
          pro_id:<span className='text-primary font-bold'>PRO-ID</span> 
        }}

        />
      </div>

      <div className={"pt-4"}>
        <Container />
      </div>

    </>
  )
}

export default FormConatiner