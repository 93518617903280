import React from 'react'
import { FormattedMessage } from 'react-intl'

const LicenseExpired = ({ deactive, templates }) => {
    return (
        <>
            <div className='flex justify-center space-x-4'>
                <img src='/images/icons/warning-sign.svg' className='w-24' />
                <div className='py-6 text-sm text-primary italic'>
                    <FormattedMessage id='your_license_expired' defaultMessage={"Your license has been expired, you are not allowed to"} />
                    <span> </span>
                    {
                        templates ?
                            <FormattedMessage id='assign_template_to_member' defaultMessage={"assign a template to any member"} />
                            : deactive ?
                                <FormattedMessage id='de_active_members' defaultMessage={"deactivate members"} />
                                :
                                <FormattedMessage id='active_more_members' defaultMessage={"activate more members"} />
                    }.
                    <FormattedMessage id='please_contact_pro_id_team' defaultMessage={"Please contact with PRO-ID team to activate your license"}/>.
                </div>
            </div>
        </>
    )
}

export default LicenseExpired