import React from 'react'
import SuperAdmin from "./SuperAdmin"
import AuthRoutes from "./AuthRoutes"
import { useSelector } from 'react-redux';
import { BUSINESS_ADMIN, SUPER_ADMIN } from '../constants';
// import BusinessAdmin from './BusinessAdmin';

const BaseRoutes = () => {
  const user = useSelector(state => state.user);

  return (
        user ?
          // user.role === BUSINESS_ADMIN ?
            <SuperAdmin />
          // : 
        :
          <AuthRoutes />
          // <SuperAdmin/>
  )
}

export default BaseRoutes