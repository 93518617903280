import { AnimatePresence, motion } from 'framer-motion';
import React, { useContext, useState } from 'react'
import { TfiAngleRight } from "react-icons/tfi";
import { BiCog } from 'react-icons/bi';
import { Link, useLocation } from 'react-router-dom';
import FeatureRequest from '../../pages/SuperAdmin/FeatureRequest';
import { FEATURE_REQUEST_URL, MY_SUBSCRIPTIN_ROUTE, POPUP_MEDIUM } from '../../constants';
import { BasePopupContext } from '../BasePopup';
import Subscription from '../../pages/SuperAdmin/Subscription';

const NestedLink = ({ icon, title, to, links }) => {
  const [open, setOpen] = useState(false);
  const { pathname } = useLocation();
  const {setPopup} = useContext(BasePopupContext)
  // const hovered = ;


  const handlePopup = (item)=>{
    console.log("item",item)
    const popup = {
      hideDefaultClose: false,
      custom_component: 
        item.to === MY_SUBSCRIPTIN_ROUTE?
          <Subscription/>
      :
        item.to === FEATURE_REQUEST_URL?
          <FeatureRequest/>
      :
        ""
      ,
      visible: true,
      heading: "",
      contained: false,
      size: POPUP_MEDIUM,
      contained: true
  }
  setPopup(popup)
  }

  return (
    <>
    <div className='relative'>

    <button onClick={() => setOpen(!open)} className={`w-[90%] mx-auto group rounded-lg justify-between flex items-center py-3 px-3`}>
      <div className='flex items-center'>
        <img src={icon} className={`flex-shrink-0 mr-3 w-[20px] ${open ? "" : "opacity-[0.65]"}`} />
        <span className={`transition-all  font-regular ${open ? "font-medium text-primary" : "group-hover:font-medium text-primary/60 group-hover:text-primary"}`}>
          {
            title
          }
        </span>
      </div>
      <TfiAngleRight className={`transition-all text-xl transform ${open ? "rotate-[90deg] text-primary" : "text-primary/60 group-hover:text-primary rotate=[0deg]"}`} />
    </button>
      <AnimatePresence>
        {
          open &&
          <motion.div
            initial={{ height: "0" }}
            animate={{ height: "auto" }}
            exit={{ height: "0" }}
            className="pl-5 overflow-hidden w-[90%] mx-auto relative"
          >
           <div className='border-l-2 border-dashed border-primary/20 space-y-1'>
            {
                links.map((item, index) => (
                  
                    item.popup?
                    <div key={index} onClick={()=>handlePopup(item)} className={`transition-all cursor-pointer  w-[90%] mx-auto group hover:bg-lighter rounded-lg justify-start flex items-center py-4 px-3 ${item.to === pathname ? "bg-lighter" :""}`}>
                      <span className={`text-sm font-regular ${item.popup ? "" : "text-primary/70 group-hover:text-primary group-hover:font-medium"}`}>
                        {
                          item.title
                        }
                      </span>
                    </div>
                    :
                    <Link key={index} to={item.to} className={`transition-all  w-[90%] mx-auto group hover:bg-lighter rounded-lg justify-start flex items-center py-4 px-3 ${item.to === pathname ? "bg-lighter" :""}`}>
                      <span className={`text-sm font-regular ${item.to === pathname ? "font-medium text-primary" : "text-primary/70 group-hover:text-primary group-hover:font-medium"}`}>
                        {
                          item.title
                        }
                      </span>
                    </Link>
                ))
              }
           </div>
          </motion.div>

        }
      </AnimatePresence>
    </div>

    </>
  )
}

export default NestedLink