import React, { useState } from 'react'
import Select from "react-select";
import { selectStylesFilter } from '../../../utils';
import { INDUSTRY_CONST } from '../../../constants';
import Skip from '../Skip';
import { FormattedMessage } from 'react-intl';

const Form = ({ setStep, step }) => {
    const [selected, setSelected] = useState(false);

    return (
        <div className='px-4 md:px-12  flex flex-col justify-between h-full py-6 md:py-16'>
            
            <div className='mb-12'>
                {/* <img src="/images/icons/arrow-left-primary.svg" className='w-[20px] cursor-pointer md:w-[40px]' onClick={()=>setStep(step-1)} /> */}

                <h2 className='mb-6 font-bold text-2xl md:text-3xl text-primary'>
                    <FormattedMessage id='we_want_to_help' defaultMessage={"We want to help"}/>
                </h2>
                <p className='text-sm md:text-base text-primary mb-6'>
                    <FormattedMessage id='as_a_final_step' defaultMessage={"As a final step, book your free Admin Onboarding with one of our success managers!"}/>                    
                </p>
                <p className='text-sm md:text-base text-primary'>
                    <FormattedMessage id='they_help_answer_team' defaultMessage={"They will help you set up your team and answer any questions you have."}/>
                </p>
            </div>


            <button
                className='btn btn-primary w-56 !text-base'
                onClick={() => {
                    setStep(2)
                }}>
                <FormattedMessage id='book_admin_onboarding' defaultMessage={"Book Admin Onboarding"}/>
            </button>

        </div>
    )
}

export default Form