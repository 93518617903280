import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../Loading';
import { Axios } from '../../api';
import axios from 'axios';
import { set_license_data } from '../../redux/slices/licenseSlice';
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import { set_sidebar } from "../../redux/slices/uiSlice";
import { network_tl } from '../../translations';
import { useIntl } from 'react-intl';
import { ACTIVE, DATE_FORMAT_DISPLAY, DATE_FORMAT_SERVER } from '../../constants';
import { set_active_member,set_all_members, set_inactive_member } from '../../redux/slices/MembersSlice';
import { DateTime } from 'luxon';

const Page = ({ children, title }) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const license  = useSelector((state) => state.license)
  const refreshLicense = license?license.refresh:false
  

  const members  = useSelector((state) => state.members)
  const activeMembers  = members.active_members?members.active_members.length>0?members.active_members:false:false
  const refreshMembers = members.refresh

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  useEffect(() => {
    if (license&&!refreshLicense){
      setLoading(false)
    }else{
      getLicenseDetails()
    }
  }, [refreshLicense, refreshMembers])


  const getLicenseDetails = async () => {
    setLoading(true)
    setError(false)
    try {
      const result = await Axios.get(`api/business/company/members/license`, { requestId: "get-license-details" });
      const expiryDate=DateTime.fromISO(result.data.payload.expiration_date).toFormat(DATE_FORMAT_SERVER)
      const currentDate = DateTime.now().toFormat(DATE_FORMAT_SERVER)


      let licensObj={
        ...result.data.payload,
        expired:currentDate > expiryDate,
        active_members:Object.keys(result.data.payload).length>0?result.data.payload.active_members : 0,
        available_license: result.data.payload&&result.data.payload.max_users ? result.data.payload.max_users - result.data.payload.active_members : 0,
        refresh:false,
      }
      
      dispatch(set_license_data(licensObj))
      await getMembers()
    } catch (e) {
      if (e.response) {
        setError(JSON.stringify(e.response.data.description))
        setLoading(false)
      } else if (axios.isCancel) {

      } else {
        setError(network_tl(intl))
        setLoading(false)
      }
    }
  }

  
  const getMembers = async () => {
    try {
      const result = await Axios.get(`api/business/company/members?limit=10000&offset=0`, { requestId: "get-active-members" });
      let activeMembers =result.data.payload&&result.data.payload.length>0&&result.data.payload.filter((item)=> item.license_id !=null )
      let inactiveMembers =result.data.payload&&result.data.payload.length>0&&result.data.payload.filter((item)=> item.license_id ===null )
      // let arr = result.data.payload.map((item) => {
      //   let first_name="";
      //   let last_name="";
      //   let mugshootName = ""

      //   if(item&&item.first_name){
      //       first_name= item.first_name.charAt(0)
      //   }

      //   if(item&&item.last_name){
      //       last_name=item.last_name.charAt(0)
      //   }
      //   mugshootName = first_name + last_name

       

      //   let obj = {
      //     label:
      //     <div className='flex space-x-3 '>
      //         <div className='flex-shrink-0 h-[30px] w-[30px] rounded-full border-[0.5px] border-gray-100 mugshot-shadow'>
      //             <div className='h-full flex justify-center items-center text-white text-sm  w-full rounded-full bg-primary bg-cover bg-center bg-no-repeat' style={{
      //                 backgroundImage: item.image&& `ur(${item.image})`}}>
      //                 {mugshootName}
      //             </div>
      //         </div>
      //         <div>
      //           <div className='text-sm'>{item.first_name + " " + item.last_name} </div>
      //           <div className='text-xs'>{item.email}</div>
      //         </div>           
      //     </div>
      //     ,
      //     value:item.id,
      //     ...item
      //   }
      //   return obj
      // })

      dispatch(set_all_members(result.data.payload))
      dispatch(set_active_member(activeMembers))
      dispatch(set_inactive_member(inactiveMembers))
      setLoading(false)
    } catch (e) {
      if (e.response) {
        setError(JSON.stringify(e.response.data.description))
        setLoading(false)


      } else if (axios.isCancel) {

      } else {
        setError(network_tl(intl))
        setLoading(false)

      }
    }
  }
  

  return (
    <>
      {
        loading && !error ?
          <Loading />
          : !loading && error ?
            <p className='text-red-500 text-center'>{error}</p>
            :
            <div className={`pt-[50px] lg:pl-[240px] w-full pb-8`}>

              <div className="w-[96%] max-w-[1600px] mx-auto">
                <div className="px-4">
                  <div className='flex justify-end lg:hidden'>
                    <button
                      onClick={() => {
                        dispatch(set_sidebar(true))
                      }}
                      className=''
                    >
                      <HiOutlineMenuAlt3 className='text-4xl text-primary' />

                    </button>
                  </div>
                  {/* HEADER */}
                  {
                    title ?
                      <div className='mb-10'>
                        <h1 className='text-3xl font-semibold text-primary'>
                          {title}
                        </h1>
                      </div>
                      :
                      null
                  }
                  {/* HEADER */}
                  <div className=''>
                    {children}
                  </div>
                </div>
              </div>
            </div>
      }

    </>
  )
}

export default Page