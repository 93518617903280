import { DateTime } from 'luxon';
import React, { useState } from 'react'
import { DATE_FORMAT_SERVER } from '../../../constants';
import {IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Axios } from '../../../api';
import { error_alert, success_alert } from '../../../utils';
import { set_onboarding } from '../../../redux/slices/uiSlice';
import { network_tl } from '../../../translations';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { BiLoaderAlt } from 'react-icons/bi';

const Form = ({selectedTime, setSelectedTime, selectedDate,selectedOption}) => {
        const intl=useIntl()
        const dispatch=useDispatch()
        const currentDate = DateTime.now().toFormat(DATE_FORMAT_SERVER)

        const [loading,setLoading]=useState(false)
        const userSelectedDate = DateTime.fromISO(selectedDate)
        // Define the start and end times for the selected date
        const startTime = userSelectedDate.set({ hour: 11, minute: 0, second: 0, millisecond: 0 });
        const endTime = userSelectedDate.set({ hour: 20, minute: 0 });
        
        // Initialize an array to store the time slots
        const timeSlots = [];
        
        // Create time slots at 15-minute intervals
        let currentTime = startTime;
        
        while (currentTime <= endTime) {
        timeSlots.push(currentTime.toISOTime()); // Push the current time in ISO format
        currentTime = currentTime.plus({ minutes: 30 }); // Add 30 minutes to the current time
        }


    const meetingBookHandler= async()=>{
        console.log("selectedDate",selectedDate)
        console.log("selectedTime",selectedTime)
        
        const combinedDateAndTime=selectedDate+" "+DateTime.fromISO(selectedTime).toFormat("hh:mm:ss")
        console.log("combinedDateAndTime",combinedDateAndTime)
        
        setLoading(true)
        let payload = {
            start_time:combinedDateAndTime,
            typ:selectedOption&&selectedOption.value
        }

        try {
            const result = await Axios.post("api/business/company/on-board-meeting/", payload, { requestId: "post-meeting" });
            success_alert(result.data.description);
            dispatch(set_onboarding(true))
            setLoading(false)
        } catch (e) {
            if (e.response) {
                error_alert(JSON.stringify(e.response.data.description))
            } else {
                error_alert(network_tl(intl))
            }
            setLoading(false)
        }
    }

    return (
        <div className='mt-10 px-4 md:px-10 md:w-[90%] mx-auto'>
            <p className='px-4 md:px-10 mb-5 text-center text-primary font-bold text-sm sm:text-base md:text-xl'>
                <FormattedMessage id='what_time_suitable' defaultMessage={"What time works best for you?"}/>
            </p>
            <p className='px-4 md:px-10 mb-5 text-center text-primary text-sm sm:text-base md:text-xl'>
            <FormattedMessage id='max_time_and_zone' defaultMessage={"30 minute meeting - Italy (GMT+1)"}/>
            
            </p>

            <div className='grid grid-cols-2 md:grid-cols-6 gap-4'>
            {
                timeSlots.map((item, index) => (
                    <button key={index} 
                    onClick={() => {
                        setSelectedTime(item)
                    }} 
                    disabled={
                        
                        !(DateTime.fromISO(selectedDate).toFormat(DATE_FORMAT_SERVER) < currentDate) ? // if selected date is less than from current date
                            DateTime.fromISO(selectedDate).toFormat(DATE_FORMAT_SERVER) === currentDate? // if selected date is equal to current date 
                                DateTime.now() > DateTime.fromISO(item)?
                                    true
                                :
                                    false
                            :
                                false
                        :
                        true
                        }
                    className={`text-sm md:text-base transition-all hover:bg-primary hover:text-white font-bold py-2 md:py-4 px-4 rounded-lg border-primary disabled:bg-gray-100 disabled:hover:text-primary disabled:cursor-not-allowed  ${DateTime.fromISO(selectedTime).toFormat("h:mm a") === DateTime.fromISO(item).toFormat("h:mm a") ? "border-0 bg-gradient-to-b from-secondary to-primary text-white" : "text-primary border-2"}`}>
                        {DateTime.fromISO(item).toFormat("h:mm a")}
                    </button>
                ))
            }

            
            </div>
            
                <div className='pt-8 flex justify-end'>
                    <button className=' btn btn-primary !w-40' disabled={!selectedTime || loading} 
                    onClick={()=>meetingBookHandler()}
                    >
                        {
                            loading?
                            <BiLoaderAlt className='text-lg mx-auto animate-spin'/>
                            :
                            <FormattedMessage id='book' defaultMessage={"Book!"}/>
                        }
                    </button>
                </div>
        </div>
    )
}

export default Form