import React from 'react'
import { useState } from 'react'
import Welcome from './Welcome'
import { AnimatePresence, motion } from 'framer-motion'
import Help from './Help'
import Calendar from './Calendar'

const OnboardingFlow = () => {
    const [step, setStep] = useState(0)
    const [selectedOption, setSelectedOption] = useState(false);

    return (
        <div className={`md:pt-[50px] w-full md:pb-[50px]`}>

            <div className="w-full md:w-[80%] max-w-[1600px] mx-auto">
                <div className="md:px-4">
                    <AnimatePresence mode="wait">
                    <motion.div
                        initial={{
                            opacity: 0,
                            scale: 0.8
                        }}
                        animate={{
                            opacity: 1,
                            scale: 1
                        }}
                        exit={{
                            opacity: 0,
                            scale: 1.1
                        }}
                        transition={{
                            type: "tween",
                            duration: 0.3
                        }}
                        key={step}
                    >
                        {
                            step === 0 ?
                                <Welcome 
                                    setStep={setStep}
                                    setSelectedOption={setSelectedOption}
                                    selectedOption={selectedOption}
                                />
                            : step === 1 ?
                                <Help 
                                    setStep={setStep}
                                    step={step}
                                />
                            : step === 2 ?
                                <Calendar 
                                    setStep={setStep} step={step}
                                    setSelectedOption={setSelectedOption}
                                    selectedOption={selectedOption}
                                />
                            : 
                                null
                        }
                    </motion.div>
                    </AnimatePresence>
                </div>
            </div>
        </div>
    )
}

export default OnboardingFlow