import React, {createContext, useContext, useEffect} from 'react'
import {AnimatePresence, motion} from "framer-motion";
import {GrClose} from "react-icons/gr";
import {IoClose} from "react-icons/io5";
import { INFO, POPUP_MEDIUM, POPUP_SMALL, SUCCESS, WARNING } from '../../constants';
import { AiOutlineCheckCircle, AiOutlineInfoCircle, AiOutlineWarning } from 'react-icons/ai';

//global context for popup
export const BasePopupContext = createContext(null);

// initial state for popup
const initialState = {
    component: "",
    custom_component: "",
    visible: false,
    size: "small",
    heading: "",
    contained: false,
    hideDefaultClose: false
}

const BasePopup = () => {

    //extracting state from context
    const {popupState, setPopup} = useContext(BasePopupContext);
    const {component, heading, size, visible, contained, hideDefaultClose, type,custom_component} = popupState;

    const hidePopup = () => {
        setPopup({visible: false, hideDefaultClose: false});
    }

    // this is a helper component which sets the base for a modal
    const variants = {
        initial: {
            opacity: 0,
            top: "-100%",
        },
        enter: {
            opacity: 1,
            top: "0"
        },
        exit: {
            opacity: 1,
            top:"-100%",
        }
    }

    const sizeClass = size === POPUP_SMALL ? "w-full md:w-96 lg:w-6/12 xl:w-5/12 2xl:w-3/12 max-w-[500px]" : size === POPUP_MEDIUM ? "w-full lg:w-7/12 xl:w-6/12 2xl:w-5/12 max-w-[700px]" : "w-10/12 max-w-container";
    return (
            <AnimatePresence>
                    {visible &&
                        <PopupContent 
                            hideDefaultClose={hideDefaultClose}
                            variants={variants}
                            heading={heading}
                            contained={contained}
                            hidePopup={hidePopup}
                            component={component} 
                            custom_component={custom_component} 
                            sizeClass={sizeClass}
                            setPopup={setPopup}
                            type={type}
                        />
                    }
            </AnimatePresence>
    )
}

const PopupContent = ({variants, heading, contained, hideDefaultClose, hidePopup, component, sizeClass, setPopup, type,custom_component}) => {

    useEffect(() => {
        return () => {
            // reset popup to initial state on unmount
            setPopup(initialState)
        }
    })

    return(
        <>
        {
            custom_component?
            <div className="bg-primary/30 backdrop-blur-sm fixed top-0 left-0 h-screen w-screen flex items-start pt-16 pb-8 justify-center z-[1000] overflow-y-auto">
                <motion.div
                    transition={{ type: "tween" }}
                    initial="initial"
                    animate="enter"
                    exit="exit"
                    variants={variants}
                    className={`px-8 py-6 sm:px-12 sm:py-12 w-full lg:w-6/12 xl:w-7/12 2xl:w-9/12 max-w-[800px] bg-white shadow-lg rounded-3xl border-1 border-gray-200 relative mx-auto`}>
                    {custom_component}
                </motion.div>
            </div>
            :
        <div className="bg-black backdrop-blur-sm bg-opacity-30 left-0 top-0 fixed h-screen w-screen flex items-start pt-16 pb-8 justify-center z-[1000] overflow-y-auto">
            <motion.div
            transition={{type: "tween"}}
            initial="initial"
            animate="enter"
            exit="exit"
            variants={variants}
            className={`${sizeClass} bg-white shadow-lg rounded-lg border-1 border-gray-200 relative mx-auto`}>
                {/* header */}
                <div className="rounded-lg rounded-b-none border-b-2 border-b-gray-100 p-4 flex items-center justify-between">
                    <div className='flex items-center space-x-3'>
                        {
                            type === INFO ?
                                <div className="rounded-full bg-lighter h-10 w-10 flex items-center justify-center">
                                    <AiOutlineInfoCircle className='text-xl text-primary'/>
                                </div>
                            : type === WARNING ?
                                <div className="rounded-full bg-amber-100 h-10 w-10 flex items-center justify-center">
                                    <AiOutlineWarning className='text-xl text-amber-500'/>
                                </div>
                            : type === SUCCESS ?
                                <div className="rounded-full bg-emerald-100 h-10 w-10 flex items-center justify-center">
                                    <AiOutlineCheckCircle className='text-xl text-emerald-500'/>
                                </div>
                            :
                            <div className="rounded-full bg-lighter h-10 w-10 flex items-center justify-center">
                                <AiOutlineInfoCircle className='text-xl text-primary'/>
                            </div>
                        }
                        <div>
                        <p className="text-xl font-bold text-primary">
                            {heading}
                        </p>
                        </div>
                    </div>
                    <div className={`text-white text-3xl cursor-pointer ${hideDefaultClose && "hidden"}`} onClick={hidePopup}>
                        <IoClose className='text-gray-600'/>
                    </div>
                </div>
                {/* header */}
                
                <div className={contained ? "px-5 py-8" : ""}>
                    {component}
                </div>
            </motion.div>
        </div>
        }
        </>
    )
}

export default BasePopup
