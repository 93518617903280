// all generic progammable translations are inside this file
// please make sure to append "_tl" to all the names of strings

export const required_tl = (intl) => intl.formatMessage({id:"required", defaultMessage:"Required"})
export const network_tl = (intl) => intl.formatMessage({id:"network.error", defaultMessage:"A Network Error Occured! Please try again"})
export const passwords_match_tl = (intl) => intl.formatMessage({id:"passwords.must.match", defaultMessage:"Passwords must match"})
export const password_short_tl = (intl) => intl.formatMessage({id:"password.short", defaultMessage:"Password is too short, at least make it 8 characters long."})
export const password_smallcase_tl = (intl) => intl.formatMessage({id:"password.smallcase", defaultMessage:"Password must contain a small case letter."})
export const password_uppercase_tl = (intl) => intl.formatMessage({id:"password.uppercase", defaultMessage:"Password must contain a uppercase case letter."})
export const password_number_tl = (intl) => intl.formatMessage({id:"password.number", defaultMessage:"Password must contain a number."})
export const password_special_tl = (intl) => intl.formatMessage({id:"password.special", defaultMessage:"Password must contain a special character."})
export const email_tl = (intl) => intl.formatMessage({id:"valid_email", defaultMessage:"Please enter a valid email"})
export const submit_tl = (intl) => intl.formatMessage({id:"submit", defaultMessage:"Submit"})
export const max_tl_100 = (intl) => intl.formatMessage({id:"max.tl.100", defaultMessage:"Only 100 Characters allowed"})
export const max_tl_1000 = (intl) => intl.formatMessage({id:"max.tl.1000", defaultMessage:"Only 1000 Characters allowed"})
export const alphabet_only_tl = (intl) => intl.formatMessage({id:"alphabet.only", defaultMessage:"Use letters only, no spaces or special characters"})