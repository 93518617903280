import { configureStore } from "@reduxjs/toolkit";

//reducers below
import userReducer from "./slices/userSlice";
import uiReducer from "./slices/uiSlice";
import categorySlice from "./slices/categorySlice";
import AttibutesSlice from "./slices/AttibutesSlice";
import licenseSlice from "./slices/licenseSlice";
import MembersSlice from "./slices/MembersSlice";
//reducers above

export default configureStore({
    reducer:{
        user: userReducer,
        ui: uiReducer,
        categories: categorySlice,
        Attibutes: AttibutesSlice,
        license: licenseSlice,
        members:MembersSlice
    } 
})
