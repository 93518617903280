import React, { useContext, useState } from 'react'
import { Formik, Form } from "formik";
import * as yup from "yup";
import { error_alert, hidePopup, stringNotEmpty, success_alert } from '../../../../utils';
import TextareaField from '../../../../components/FormikFields/TextareaField';
import TextField from '../../../../components/FormikFields/TextField';
import { FormattedMessage, useIntl } from 'react-intl';
import { BasePopupContext } from '../../../../components/BasePopup';
import { useEffect } from 'react';
import { Axios } from '../../../../api';
import { BiLoaderAlt } from 'react-icons/bi';
import { network_tl } from '../../../../translations';

const NotificationForm = () => {
    const intl = useIntl();
    const { setPopup } = useContext(BasePopupContext)

    const [loading, setLoading] = useState(false)
    const initialValues = {
        message: "",
    }
    yup.addMethod(yup.string, 'stringNotEmpty', stringNotEmpty)

    const validationSchema = yup.object({
        message: yup.string().stringNotEmpty(),
    })

    const handleSubmit = async (values, formikBag) => {
        setLoading(true)
        let payload = {
            request: values.message
        }

        try {

            const result = await Axios.post(`api/business/feature-request`, payload)
            success_alert(result.data.description)
            formikBag.resetForm()
            setLoading(false)
            hidePopup(setPopup)

        } catch (e) {
            if (e.response) {
                error_alert(JSON.stringify(e.response.data.description))
            } else {
                error_alert(network_tl(intl))
            }
            setLoading(false)
        }
    }

    return (
        <div className=''>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {
                    form => (
                        <Form>
                            <div className='mb-5 space-y-5'>
                                <div>
                                    <TextareaField
                                        label_text={<FormattedMessage id='message' defaultMessage={"Message"}/>}
                                        helper_text={"Message"}
                                        form={form}
                                        field={"message"}
                                    />
                                </div>

                            </div>

                            <div className='flex justify-between'>
                                <div></div>
                                <div className='flex space-x-3'>
                                    <button className='btn btn-cancel' onClick={() => hidePopup(setPopup)}>
                                        <FormattedMessage id='cancel' defaultMessage={"Cancel"}/>
                                    </button>
                                    <button className='btn btn-primary' disabled={ (!form.values.message || loading)}>
                                        {
                                            loading ?
                                                <BiLoaderAlt className="text-white animate-spin mx-auto text-lg" />
                                                :
                                                <FormattedMessage id='send_feedback' defaultMessage={"Send FeedBack"}/>
                                        }
                                    </button>

                                </div>
                            </div>
                        </Form>
                    )
                }
            </Formik>
        </div>
    )
}

export default NotificationForm